import {
  IonBadge,
} from '@ionic/react';
import { useDispatch } from 'react-redux';
import { VoteIcon, getIcon } from './VoteIcons';
import usePointingDevice from '../hooks/PointingDevice';
import { User, Group, Question, IconType, InfoContext } from '../app/utilities/types';
import { setAnswer, removeAnswer } from '../store/quiz.slice';
import { calcCombinedPoints } from '../app/utilities/voteCombiner';
import Particles from '../app/utilities/particles';
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import './Votes.css';




import { useState, useRef, useEffect } from 'react';


  const onVoteHandler = (question: Question, choice: number, event: SpanMouseEvent,
    localUser: User | null, dispatch: any
  ) => {
    //console.log(`onVote: choice=${choice} question.iconType=${question.iconType} localUser=${JSON.stringify(localUser)}`);

    //Particles.emitIcons(event.currentTarget, question.iconType, choice);

    dispatch(setAnswer({questionId: question.id, userId: localUser!.id, choice}));
  };


type SpanMouseEvent = React.MouseEvent<HTMLSpanElement, MouseEvent>;
type SpanMouseEventHandler = React.MouseEventHandler<HTMLSpanElement>;


// const voteRatingSmallestFraction = .25;
const voteRatingSmallestFraction = .5;

/*
const countVotes = (question: Question, user?: User|null): number|null => {
  //! question.answers.reduce((totalVotes, answer) => totalVotes += answer.choice, 0) / (question.answers.length || 1);
  //console.log(`countVotes: question=${JSON.stringify(question.title)} num users=${users.length} total votes=${question.answers.reduce((totalVotes, answer) => totalVotes += answer.choice, 0)}`);

  // Check if user has abstained from voting
  if (user && question.answers.some(answer => answer.userId === user.id && answer.abstained)) {
      return null;
  }

  const {total: totalVotes, num: numVotes} = question.answers
    // Filter by user if one was passed as an argument
    .filter(answer => (!user || answer.userId === user.id) && !answer.abstained)
    .reduce((votes, answer) =>
      ({total: votes.total + (answer.choice ?? 0), num: votes.num + 1}), {total: 0, num: 0});

//  return totalVotes / (numVotes || 1);
    const voteCount = totalVotes / (numVotes || 1);
//console.log(`countVotes:  voteCount=${voteCount} typeof voteCount=${typeof voteCount}`);
    return voteCount;
};
*/
const countVotes = (question: Question, user: User): number|null => {
  // Check if user has abstained from voting
  if (question.answers.some(answer => answer.userId === user.id && answer.abstained)) {
      return null;
  }

  const {total: totalVotes, num: numVotes} = question.answers
    // Filter by user if one was passed as an argument
    .filter(answer => answer.userId === user.id && !answer.abstained)
    .reduce((votes, answer) =>
      ({total: votes.total + (answer.choice ?? 0), num: votes.num + 1}), {total: 0, num: 0});

    const voteCount = totalVotes / (numVotes || 1);
    return voteCount;
};

interface Props {
  question: Question,
  localUser: User | null,
  isVoting: boolean,
  infoContext?: InfoContext,
  users: User[],
  groups: Group[],
}

export const Votes: React.FC<Props> = ({
    question,
    localUser,
    isVoting,
    infoContext = "main",
    users,
    groups,
}) => {

  const dispatch = useDispatch();

  const prevNumVotes = useRef<number | null>(-1);


  // const ratingIndex = useRef(-1);
  const lastRatingIndex = useRef(-1);
  const choiceFractionDecrement = useRef(0);

  const [pressedIndex, setPressedIndex] = useState(-1);
  const [longPressedIndex, setLongPressedIndex] = useState(-1);
  const [isTouching, setIsTouching] = useState(false);

  const {
    action,
    handlers,
  } = usePointingDevice(
      handlePressStart,
      handleLongPressStart,
      handlePressEnd,
      handlePress,
      handleQuickPress,
      handleDoublePress,
      handleDrag,
    );

// TODO useCallbacks
  function handlePressStart(event: any, isTouching: boolean) {
    const ratingIndex = Number(event.currentTarget.getAttribute('data-rating-index'));
    setPressedIndex(ratingIndex);
    setLongPressedIndex(-1);
    setIsTouching(isTouching);
  }

  function handleLongPressStart(event: any) {
const ratingIndex = Number(event.currentTarget.getAttribute('data-rating-index'));
    setLongPressedIndex(ratingIndex);
    // console.log('lastRatingIndex IN TIMEOUT LONG PRESSED =', ratingIndex);
  }

function handlePressEnd(event: any) {
  setPressedIndex(-1);
  setLongPressedIndex(-1);
  setIsTouching(false);
}

  function handleQuickPress(event: any) {
    // console.log("quickpress");

    // advanceChoiceFractionDecrement
    // Have to be careful the simultaneously fired onTouchEnd event handler doesn't do this also for mobile devices!
    if (choiceFractionDecrement.current === 0) {
      choiceFractionDecrement.current = 1;
    }
    /* Without the else, the icon will not go back to fully empty on quick pressing - could make this a configuration option */
    else {
      choiceFractionDecrement.current -= voteRatingSmallestFraction;
    }

    //?
    const hack = true;
    handlePress(event, hack);
  }

  function handlePress(event: any, hack: boolean) {
    //alert("handlePress: ratingIndex="+ratingIndex);
    let ratingIndex = Number(event.currentTarget.getAttribute('data-rating-index'));

    // advanceChoiceFractionDecrement(ratingIndex);
    // console.log('lastRatingIndex.current=', lastRatingIndex.current, 'ratingIndex=', ratingIndex, 'numVotes=', numVotes);

if (!hack)
    if (ratingIndex !== lastRatingIndex.current) {
      lastRatingIndex.current = ratingIndex;
  //pressStartTime.current = 
//????????????????????????????????  prevPressStartTime.current = pressStartTime.current;
prevNumVotes.current = ratingIndex + 1;

      choiceFractionDecrement.current = 0;
    }

    // Pressing on already selected rating will decrement it causing it to become effectively deselected
if (!hack) {
    if (ratingIndex + 1 === Math.ceil(numVotes || 0)) {
      // Do not decrement rating if partially selected (effectively to make it a whole selection)
      if (((numVotes || 0)) % 1 === 0) {
        ratingIndex -= 1;
      }
      choiceFractionDecrement.current = 0;
    }

    // Needed to prevent empty ratings becoming 'stuck' when quick pressed there
    if (choiceFractionDecrement.current === 1) {
      choiceFractionDecrement.current = 0;
    }

    // alert(choiceFractionDecrement.current);
  }

    const choice = (ratingIndex + 1) - choiceFractionDecrement.current;
    onVoteHandler(question, choice, event,
      localUser, dispatch);

    // console.log('lastRatingIndex.current=', lastRatingIndex.current, 'choiceFractionDecrement.current=', choiceFractionDecrement.current);
  }

  function handleDoublePress(event: any) {
    // console.log('in handleOnDoubleClick: longPressedIndex=' + longPressedIndex);
    // if (longPressedIndex !== -1) {
    //   console.log('Is long press - not continuing.');
    //   return;
    // }
    //setAction('click');

const ratingIndex = prevNumVotes.current !== maxVotes ? maxVotes : 0;
lastRatingIndex.current = ratingIndex;
prevNumVotes.current = ratingIndex;

    choiceFractionDecrement.current = 0;

//!!    const choice = (ratingIndex + 1) - choiceFractionDecrement.current;
    const choice = ratingIndex;
    onVoteHandler(question, choice, event,
      localUser, dispatch);

    action.current = 'double click';
  }

  function handleDrag(event: any, normalOffset: {x: number, y: number}) {
//return;
    // console.log('handleDrag normalOffsetX=', normalOffset.x);
    // console.log('handleDrag normalOffsetY=', normalOffset.y);
  
    choiceFractionDecrement.current = 1 - normalOffset.x;
    choiceFractionDecrement.current = Math.floor(choiceFractionDecrement.current / voteRatingSmallestFraction) * voteRatingSmallestFraction;

//! TEMP
//choiceFractionDecrement.current = 0;

    const hack = true;
    handlePress(event, hack);
  }

/*
  const onVoteHandler = (question: Question, choice: number, event: SpanMouseEvent) => {
    
//! TEMP!
choice = Math.floor(choice) - choiceFractionDec;

    console.log(`onVote: choiceFractionDec=${choiceFractionDec} choice=${choice} question.iconType=${question.iconType} localUser=${JSON.stringify(localUser)}`);

    Particles.emitIcons(event.currentTarget, question.iconType, choice);

    dispatch(setAnswer({questionId: question.id, userId: localUser!.id, choice}));
  };
*/
  const onRemoveVoteHandler = (question: Question, event: SpanMouseEvent) => {
    dispatch(removeAnswer({questionId: question.id, userId: localUser!.id}));
  };

  const onAbstainVoteHandler = (question: Question, event: SpanMouseEvent) => {
    dispatch(removeAnswer({questionId: question.id, userId: localUser!.id, abstained: true}));
  };

  const showAverageVotes = !isVoting;
  //! const user = !showAverageVotes ? localUser : null;
  //! const numVotes = countVotes(question, localUser);
  let numVotes: number|null = 0;
  let totalPoints = 0, numAnswers = 0;
  if (showAverageVotes) {
    // const {totalPoints, numAnswers} = calcCombinedPoints(question.answers, users, groups);
    const combinedPoints = calcCombinedPoints(question, users, groups);
    totalPoints = combinedPoints.totalPoints;
    numAnswers = combinedPoints.numAnswers;
    if (numAnswers) {
      numVotes = totalPoints / numAnswers;
    }
  }
  else {
    //TODO PERHAPS ALSO RETURN GROUP'S COMBINED (AVERAGED ETC) VOTE SO THIS COULD BE SOMEHOW SHOWN ALSO
    numVotes = countVotes(question, localUser!);
  }

  const numWholeVotes = Math.floor(numVotes ?? 0);

// TODO Math.ceil THEN USE MASK_IMAGE WITH GRADIENT
// TODO mask-image: linear-gradient(90deg, rgba(0, 0, 0, 1.0), rgba(0, 0, 0, 1.0) 2%, transparent 2%);
// TODO TO PARTIALLY REVEAL FINAL STAR
// TODO USE https://autoprefixer.github.io/ FOR BROWSER PREFIXES
  const maxVotes = question?.maxVotes;

  // const numIconsToShow = showAverageVotes ? Math.floor(numVotes ?? 0) : maxVotes;
  const numIconsToShow = maxVotes;

  const percentageVotes = Math.round((numVotes ?? 0) * 100) / maxVotes;
  
  // console.log('percentageVotes=', percentageVotes, 'numVotes=', numVotes, 'maxVotes=', maxVotes);

  const ratingFeedbackLabels = ['Mauvais', 'Médiocre', 'Moyen', 'Bien', 'Excellent'];
  const ratingValue = numVotes ?? 0;

  // TODO Hack to remove handlers for sidebar or if not voting (e.g. is votee and can't vote for self)
  const isInteractive = infoContext === "main" && isVoting;

  return (
    <div
      className={`votes ${isInteractive ? "interactive" : ""}`}
      data-num-votes={numVotes}
      data-num-votes-rounded-up={Math.ceil(numVotes ?? 0)}
    >
      <div className={`vote-icons ${numVotes === null ? 'abstained' : ''}`}>

<div
  className="rr--group rr--dir-x rr--has-stroke rr--space-sm
  rr--has-border rr--fx-zoom rr--fx-colors rr--rx-lg rr--pointer"
  // role="img"
  // aria-label="Rated 0.29 on 5"
>
        {Array(numIconsToShow).fill(null).map((_, i) => {
          let title = "";
          const style: any = {};

          if (!showAverageVotes) {
            // TODO PUT RATING HERE INSTEAD
            // title = `Votez ${i + 1} point${i !== 0 ? 's' : ''}`;
//!!!            title = ratingFeedbackLabels[i];
            // if (isInteractive) {
            //   style.cursor = "pointer";
            //   style.transform = "scale(1.2)";
            // }
          }

          const voteIconEmpty = false; // i >= (numVotes ?? 0);

          const fillPercent = (i === numWholeVotes
            ? (numVotes ?? 0) - numWholeVotes
            : (i >= (numVotes ?? 0) ? 0 : 1)) * 100;
  
          style.color = voteIconEmpty
            ? getIcon(question.iconType).fadedColour
            : getIcon(question.iconType).colour;

          return (
            <span
              key={i}
              data-rating-index={i}
              {...(isInteractive ? handlers : {})}
              title={title}
              style={style}
              className={`vote-icon
                ${isTouching ? "touching" : ""}
                scale--${longPressedIndex === i
                  ? "large"
                  : (pressedIndex === i ? "medium" : "normal")}`}
            >
              <VoteIcon
                // TODO Find better way of ensuring unique id!
                id={`vote-rating-${question.id}_${i}_${infoContext}--${Date.now()}-${Math.random()}`}
                iconType={question.iconType}
                fillPercent={fillPercent}
                // TODO rename isActive or something?
                outline={fillPercent !== 0}
              />

              {/* <span
                key={`vote-label-${i}`}
                style={{
                  opacity: i + 1 === ratingValue ? 1 : 0.35,
                  textDecoration: i + 1 === ratingValue ? 'underline' : 'inherit',
                  // padding: '0 5%',
                }}
              >
                {ratingFeedbackLabels[i]}
              </span> */}
            </span>
          );
        })}

</div>

      </div>

      <div
        style={{
          textAlign: 'center',
          padding: '5px 0',
          fontSize: '1rem',
          fontWeight: 'bold',
          color: '#555',
          width: '100px',
        }}
      >
        {/* To display a rating feedback */}
        {/* {numWholeVotes > 0
          ? ratingFeedbackLabels[numWholeVotes - 1]
          : (isInteractive && (numVotes ?? 0) === 0 && <em style={{fontSize: '.9rem', fontWeight: 'normal'}}>Veuillez voter</em>)
        } */}
        {numWholeVotes === 0 &&
          (isInteractive && (numVotes ?? 0) === 0 && <em style={{fontSize: '.9rem', fontWeight: 'normal'}}>Veuillez voter</em>)
        }
      </div>

      {infoContext === "main" && <>
        {!isVoting && <div className="vote-stats">
          {/* <div style={{fontSize: '.4em', marginLeft: 10, position: 'relative', top: -6}}>
            {`${Math.round((numVotes ?? 0) * 100) / 100} / ${maxVotes}`}
          </div> */}

          {!isVoting && percentageVotes !== 0 &&
            <div className="percentage-votes" style={{fontSize: '.4em', marginLeft: 10, position: 'relative', top: -6}}>
              <CountdownCircleTimer
                key={percentageVotes}
                isPlaying={false}
                duration={100}
                initialRemainingTime={100 - percentageVotes}
                colors="#000000"
                size={80}
                strokeLinecap="square"
                strokeWidth={8}
              >{
                ({ remainingTime, elapsedTime }) => {
                  return (
                    <div className="timer" style={{fontSize: '1.2rem'}}>
                      <div className="value">{100 - remainingTime} %</div>
                    </div>
                  );
                }
              }
              </CountdownCircleTimer>
            </div>
          }
        </div>}

        {!isInteractive && <IonBadge color="secondary" style={{fontSize: "1.6em", marginLeft: 25}}>{totalPoints}</IonBadge>}

        {isVoting && <>
          <span className="delete-votes" onClick={onRemoveVoteHandler.bind(null, question)} title="Retirez les votes pour ce critère">
            <VoteIcon iconType={"erase" as IconType} colour={getIcon("erase").colour} size={30} isNonRatingIconHack={true} />
          </span>
          <span className="abstain-from-voting" onClick={onAbstainVoteHandler.bind(null, question)} title="Vous vous abstenez de voter pour ce critère">
            <VoteIcon iconType={"crossCircle" as IconType} colour={getIcon("crossCircle").colour} size={26}  isNonRatingIconHack={true} />
          </span>
        </>}
      </>}
    </div>
  );
};

/*
  <span style={{fontSize: '4rem'}}>
    {numIconsToShow > 0 ? Array(numIconsToShow).fill(null).map((_, i) => {
      let clickHandler = (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {};
      let title = "";
      const style: any = {};

      if (!showAverageVotes) {
        clickHandler = (event) => onVote(event, question, i + 1);
        title = `Votez ${i + 1} point${i !== 0 ? 's' : ''}`;
        style.cursor = "pointer";
      }

      style.color = i < numVotes
        ? getIcon(question.iconType).colour
        : getIcon(question.iconType).fadedColour

      return (
        <span
          key={`vote-icon-${i}`}
          onClick={clickHandler}
          title={title}
          style={style}
        >
          <VoteIcon iconType={question.iconType} />
        </span>
      );
    }) : null}
  </span>
*/
